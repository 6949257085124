// calendar 전체
.react-calendar {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;

    width: 336px;
    height: 100%;
    // padding: 16px;

    border: none;

    .react-calendar__tile--now {
        background-color: #fff;
    }

    .react-calendar__navigation button:disabled {
        background-color: #fff;
        color: #CECECE;
    }

    .react-calendar__tile:disabled {
        background-color: #fff;
        color: #CECECE;
    }

    // header
    .react-calendar__navigation {
        // height: 64px;
        height: 48px;
        margin: 12px 0;



        .react-calendar__navigation__prev-button,
        .react-calendar__navigation__next-button {
            width: 100px;
            padding-bottom: 6px;

            font-size: 32px;
            font-weight: 100;
            color: #000;
        }

        .react-calendar__navigation__label {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 400px;

            &>span {
                font-size: 16px;
                font-weight: 700;
                color: #000;
            }
        }

    }

    // content
    .react-calendar__viewContainer {
        // height: 100%;

        // 요일
        .react-calendar__month-view__weekdays {
            >div>abbr[title] {
                color: #585858;
                font-size: 13px;
                text-decoration: none;
                font-weight: 500;
            }

            margin-bottom: 12px;
        }

        // 일자
        .react-calendar__month-view__days {

            // 일반
            >button {
                height: 42px;
                margin: 3px 0;

                >abbr {
                    position: relative;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 23px;
                    left: 0px;

                    @media screen and (max-width: 360px) {
                        left: 1px;
                    }
                }
            }

            // onSelect
            & button.react-calendar__tile--active {
                >abbr {
                    position: relative;
                    color: #000;
                    font-weight: 700;
                    line-height: 23px;
                    left: 0px;

                    @media screen and (max-width: 360px) {
                        left: 1px;
                    }

                }
            }

            & button.react-calendar__tile--active,
            & button.react-calendar__tile--active:enabled:hover,
            & button.react-calendar__tile--active:enabled:focus {
                background: #FFCD00;
                border-radius: 24px;
            }

            & button.react-calendar__tile--range {
                border-radius: 0;
                background: #FFF4B5;
            }

            & button.react-calendar__tile--rangeStart {
                border-radius: 0 !important;
                background: linear-gradient(to right,
                        #FFFFFF 0%,
                        #FFFFFF 50%,
                        #FFF4B5 50%,
                        #FFF4B5 100%) !important;
                position: relative;
                overflow: hidden;
                > abbr {
                    font-weight: 700;
                }
            }

            & button.react-calendar__tile--rangeEnd {
                border-radius: 0 !important;
                background: linear-gradient(to right,
                        #FFF4B5 0%,
                        #FFF4B5 50%,
                        #FFFFFF 50%,
                        #FFFFFF 100%) !important;
                position: relative;
                overflow: hidden;
                > abbr {
                    font-weight: 700;
                }
            }

            & button.react-calendar__tile--rangeStart::before,
            & button.react-calendar__tile--rangeEnd::before {
                content: '';
                background: #FFCD00;
                position: absolute;
                top: 0;
                left: 3px;
                right: 3px;
                width: 42px;
                height: 42px;
                border-radius: 100%;
            }

            & button.react-calendar__tile--rangeBothEnds {
                border-radius: 24px !important;
                background: #FFFFFF !important;

            }
        }

        // 구매 기준일
        .react-calendar__add-purchase__base-day {
            position: relative;
            // background-color: #FFFFFF;

            &::before{
                content: '';
                background: #FFED99;
                position: absolute;
                top: 0;
                left: 3px;
                right: 3px;
                width: 42px;
                height: 42px;
                border-radius: 100%;

                @media screen and (max-width: 380px) {
                    width: 92%;
                    transition: all ease 0.1s 0s;
                }
            }
            abbr{
                color: #CCA700;
                font-weight: 700 !important;
            }
        }
        // 구매 가능한 날짜
        .react-calendar__add-purchase__available {
            position: relative;
            background-color: #FFFFFF;

            &::before{
                content: '';
                border: 1px solid #FFCD00;
                background: #FFFFFF !important;
                position: absolute;
                top: 0;
                left: 3px;
                right: 3px;
                width: 42px;
                height: 42px;
                border-radius: 100%;
                // transition: all ease 0.1s 0s;

                @media screen and (max-width: 380px) {
                    width: 92%;
                    transition: all ease 0.1s 0s;
                }
            }
            abbr{
                font-weight: 400 !important;
            }
        }
    }
}