// 현재 사용자 위치
.current-overlay-container{
    z-index: 1;
    // border: 1px solid red;
    position: absolute;
    left:0;
    top:0;

    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 40px;
    height: 40px;

    .current-overlay-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        border-radius: 50%;
        background: rgba(51, 102, 255, 0.3);

        animation: target_image 2s;
        animation-iteration-count: infinite;
        /* transform-origin: 50% 50%; */

        @keyframes target_image {
            0% {
                transform: scale(0.7);
            }

            100% {
                opacity: 0;
                transform: scale(1);
            }
        }
    }

    .current-overlay-circle1,
    .current-overlay-circle2,
    .current-overlay-circle3{
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        opacity: 1;
        border-radius: 50%;
        background-color: #3366FF;
    }
    .current-overlay-circle1 {
        width: 25px;
        height: 25px;
    }
    .current-overlay-circle2 {
        width: 20px;
        height: 20px;

        background-color: #ffffff;
    }
    .current-overlay-circle3 {
        width: 15px;
        height: 15px;
    }
}

// 정류장 선택
.active-station-overlay-container{
    width: 54px;
    height: 54px;

    background-image: url('../images/reservation/ic_busstop_active@4x.png');
    background-size: 54px;
}

// 출발
.departure-overlay-container{
    // z-index: 1;
    // position: absolute;
    // top : 50%;
    // left : 50%;
    // transform: translate(-50%, -50%);

    width: 54px;
    height: 54px;

    background-image: url('../images/reservation/icon_departure.png');
    background-size: 54px;
}

// 도착
.arrival-overlay-container{
    // z-index: 1;
    // position: absolute;
    // top : 50%;
    // left : 50%;
    // transform: translate(-50%, -50%);

    width: 54px;
    height: 54px;

    background-image: url('../images/reservation/icon_arrived.png');
    background-size: 54px;
}

.place-overlay-container{
    width: 48px;
    height: 48px;

    background-image: url('../images/reservation/icon-marker@4x.png');
    background-size: 48px;
}
