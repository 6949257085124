@charset "utf-8";

@import "./reset.scss";
@import "./muiStyle.scss";
@import "./antDesignStyle.scss";
@import "./datePickerStyle.scss";
@import "./timePickerStyle.scss";
@import "./kakaoMapStyle.scss";
@import "./swipeableBottomSheetStyle.scss";


@font-face {
  font-family: NanumSquareRound;
  src: url("../fonts/NanumSquareRound/NanumSquareRoundL.ttf");
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: NanumSquareRound;
  src: url("../fonts/NanumSquareRound/NanumSquareRoundR.ttf");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: NanumSquareRound;
  src: url("../fonts/NanumSquareRound/NanumSquareRoundB.ttf");
  font-weight: 700;
  font-display: swap;
}

*{ font-family: 'NanumSquareRound' }



/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  font-size:62.5%;
}
ol, ul {
  list-style: none;
}
button {
  cursor: pointer;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a { 
  color: #585858;
  text-decoration: none; 
}
input:focus {outline-color: #2683F2;}

/*본문과의 구분을 위한 여백*/
h1{
  letter-spacing:3px;
}
h2{
  word-spacing:2px;
}
/* --여기까지--*/

span, label, p { font-size: 14px }
.t{border:1px solid #F00;}

select{font-size:14px; line-height:20px; padding:5px 0;}
hr{ border:none; border-bottom:1px solid #e5e5e5;}

.MuiTable-root{
  border-collapse: separate !important;
}
