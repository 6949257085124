#timePicker{
    overflow: hidden;
    
    .swiper-initialized{
        width: 100%;
        height: 100%;
        overflow: hidden;
    
        .swiper-slide{
            height: 45px;
        
            &.swiper-slide-active{
                background-color: #FFF4B5;
            }
        }
    }
}
