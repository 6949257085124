// Drawer
.ant-drawer-body{
    padding: 0;
}
// // Drawer

// Input
// .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
//     border: 0.5px solid #CECECE;
// }
// .ant-input-affix-wrapper-focused{
//     outline: none;

//     border: 0.5px solid #FFCD00 !important;
//     // border-color: #CECECE !important;
//     // --antd-wave-shadow-color: #FFCD00 !important;
// }
// // Input