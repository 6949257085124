/** 아래 코드는 reset.css의 원본 내용 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
button,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video{
  margin: 0;
  padding: 0;
  border: 0;
  //font-size: 14px;
  //color:#454545;
  /*font: inherit;*/
  vertical-align: baseline;
  /* 보더 박스
  box-sizing:border-box;
  -o-box-sizing:border-box;
  -ms-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;*/
  letter-spacing:-0.5px;
}

// body {
//   padding: 0;
//   margin: 0;
//   position: relative;
// }

div, section, header, article {
  box-sizing: border-box;
}
/** 추가내용: 웹 브라우저 기본 사이즈 정의 */
html, body, #root {
  width: 100%;
  height: 100%;
  // height: 100vh;
  // height: calc(var(--vh, 1vh) * 100)
}
body {
  padding: 0;
  margin: 0;
  position: relative;
  height: calc(var(--vh, 1vh) * 100)
}
